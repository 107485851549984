import { useState } from 'react';
import { Calendar, Plus } from 'lucide-react';
import { CalendlyService } from '../lib/services/calendly/calendlyService';
import { toast } from 'react-hot-toast';

export default function CalendlyConnect() {
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnect = () => {
    setIsConnecting(true);
    try {
      if (!import.meta.env.VITE_CALENDLY_CLIENT_ID || !import.meta.env.VITE_CALENDLY_CLIENT_SECRET) {
        toast.error('Calendly configuration is missing. Please check your environment variables.');
        setIsConnecting(false);
        return;
      }

      const authUrl = CalendlyService.generateAuthUrl();
      console.log('Redirecting to Calendly auth URL:', authUrl);
      window.location.href = authUrl;
    } catch (error) {
      console.error('Failed to generate auth URL:', error);
      toast.error('Failed to connect to Calendly');
      setIsConnecting(false);
    }
  };

  return (
    <div className="space-y-3">
      <div className="flex items-center space-x-3">
        <Calendar className="h-5 w-5 text-indigo-600" />
        <div>
          <h3 className="font-semibold">Connect Calendly</h3>
          <p className="text-sm text-gray-600">Sync your Calendly availability</p>
        </div>
      </div>
      <button
        onClick={handleConnect}
        disabled={isConnecting}
        className="w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:bg-indigo-400 flex items-center justify-center space-x-2"
      >
        <Plus className="h-4 w-4" />
        <span>{isConnecting ? 'Connecting...' : 'Connect Calendly'}</span>
      </button>
    </div>
  );
}