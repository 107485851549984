import { useState, useCallback } from 'react';
import { Calendar as CalendarIcon, MapPin, Save, Plus } from 'lucide-react';
import { getTimezoneForLocation } from '../lib/api/timezone';
import { formatDateForTimezone, isValidTimezone } from '../lib/utils/date';
import { ItineraryStop, TimeSlot } from '../lib/types/timezone';
import { toast } from 'react-hot-toast';
import TimezoneDisplay from './TimezoneDisplay';
import TimeSlotPicker from './TimeSlotPicker';
import SaveItineraryModal from './SaveItineraryModal';
import ItineraryList from './ItineraryList';
import { useItineraries } from '../lib/hooks/useItineraries';
import { Itinerary } from '../lib/types/itinerary';

export default function ItineraryForm() {
  const [isCreating, setIsCreating] = useState(false);
  const [stops, setStops] = useState<ItineraryStop[]>([]);
  const [newStop, setNewStop] = useState<ItineraryStop>({
    location: '',
    timezone: '',
    startDate: '',
    endDate: '',
    availability: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [editingItinerary, setEditingItinerary] = useState<Itinerary | null>(null);

  const { saveItinerary, updateItinerary } = useItineraries();

  const handleLocationChange = useCallback(async (location: string) => {
    setNewStop(prev => ({ ...prev, location }));
    if (location.length > 2) {
      setIsLoading(true);
      try {
        const timezoneInfo = await getTimezoneForLocation(location);
        if (isValidTimezone(timezoneInfo.timezone)) {
          setNewStop(prev => ({
            ...prev,
            timezone: timezoneInfo.timezone,
            countryCode: timezoneInfo.countryCode,
            countryName: timezoneInfo.countryName,
            gmtOffset: timezoneInfo.gmtOffset
          }));
        } else {
          toast.error('Invalid timezone received from API');
        }
      } catch (error) {
        console.error('Error fetching timezone:', error);
        toast.error('Unable to fetch timezone information');
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const handleAvailabilityChange = useCallback((slots: TimeSlot[]) => {
    setNewStop(prev => ({ ...prev, availability: slots }));
  }, []);

  const handleAddStop = useCallback(() => {
    if (!newStop.location || !newStop.startDate || !newStop.endDate) {
      toast.error('Please fill in all required fields');
      return;
    }

    if (!isValidTimezone(newStop.timezone)) {
      toast.error('Invalid timezone. Please try a different location.');
      return;
    }

    const startDate = new Date(newStop.startDate);
    const endDate = new Date(newStop.endDate);

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      toast.error('Invalid date format');
      return;
    }

    if (startDate > endDate) {
      toast.error('Start date must be before end date');
      return;
    }

    setStops(prev => [...prev, newStop]);
    setNewStop({
      location: '',
      timezone: '',
      startDate: '',
      endDate: '',
      availability: []
    });
    toast.success('Stop added to itinerary');
  }, [newStop]);

  const handleRemoveStop = useCallback((index: number) => {
    setStops(prev => prev.filter((_, i) => i !== index));
    toast.success('Stop removed from itinerary');
  }, []);

  const handleSave = async (name: string) => {
    if (editingItinerary) {
      await updateItinerary(editingItinerary.id, {
        name,
        stops,
        updatedAt: new Date().toISOString()
      });
      setEditingItinerary(null);
    } else {
      await saveItinerary(name, stops);
    }
    setStops([]);
    setIsCreating(false);
  };

  const handleEdit = (itinerary: Itinerary) => {
    setStops(itinerary.stops);
    setEditingItinerary(itinerary);
    setIsCreating(true);
  };

  const handleStartCreating = () => {
    setIsCreating(true);
    setStops([]);
    setEditingItinerary(null);
  };

  const handleCancel = () => {
    setIsCreating(false);
    setStops([]);
    setEditingItinerary(null);
  };

  return (
    <div className="space-y-8">
      <SaveItineraryModal
        isOpen={isSaveModalOpen}
        onClose={() => setIsSaveModalOpen(false)}
        onSave={handleSave}
        stops={stops}
      />

      {!isCreating ? (
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-semibold">Your Itineraries</h3>
          <button
            onClick={handleStartCreating}
            className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
          >
            <Plus className="h-4 w-4" />
            <span>Create Itinerary</span>
          </button>
        </div>
      ) : (
        <div className="space-y-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <MapPin className="h-5 w-5 text-indigo-600" />
              <div>
                <h3 className="font-semibold">
                  {editingItinerary ? `Editing: ${editingItinerary.name}` : 'New Itinerary'}
                </h3>
                <p className="text-sm text-gray-600">Plan your journey across time zones</p>
              </div>
            </div>
            <div className="flex space-x-3">
              <button
                onClick={handleCancel}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              {stops.length > 0 && (
                <button
                  onClick={() => setIsSaveModalOpen(true)}
                  className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
                >
                  <Save className="h-4 w-4" />
                  <span>Save Itinerary</span>
                </button>
              )}
            </div>
          </div>

          <div className="space-y-6 bg-white rounded-lg p-6 shadow-sm">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Location
                </label>
                <input
                  type="text"
                  value={newStop.location}
                  onChange={(e) => handleLocationChange(e.target.value)}
                  placeholder="Enter city or location"
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
                {newStop.timezone && (
                  <TimezoneDisplay timezone={newStop.timezone} className="mt-1" />
                )}
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Start Date
                  </label>
                  <input
                    type="date"
                    value={newStop.startDate}
                    onChange={(e) => setNewStop(prev => ({ ...prev, startDate: e.target.value }))}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    End Date
                  </label>
                  <input
                    type="date"
                    value={newStop.endDate}
                    onChange={(e) => setNewStop(prev => ({ ...prev, endDate: e.target.value }))}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>
            </div>

            {newStop.timezone && (
              <TimeSlotPicker
                value={newStop.availability || []}
                onChange={handleAvailabilityChange}
                timezone={newStop.timezone}
              />
            )}

            <div className="flex justify-end">
              <button
                onClick={handleAddStop}
                className="flex items-center space-x-2 px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
              >
                <Plus className="h-4 w-4" />
                <span>Add Stop</span>
              </button>
            </div>
          </div>

          {stops.length > 0 && (
            <div className="space-y-4">
              <h4 className="font-medium">Planned Stops</h4>
              {stops.map((stop, index) => (
                <div key={index} className="bg-gray-50 rounded-lg p-4">
                  <div className="flex justify-between items-start">
                    <div>
                      <h5 className="font-medium">{stop.location}</h5>
                      <TimezoneDisplay timezone={stop.timezone} />
                      <p className="text-sm text-gray-600">
                        {formatDateForTimezone(stop.startDate, stop.timezone, 'PP')} - 
                        {formatDateForTimezone(stop.endDate, stop.timezone, 'PP')}
                      </p>
                      {stop.availability && stop.availability.length > 0 && (
                        <div className="mt-2">
                          <p className="text-sm font-medium">Available Times:</p>
                          <ul className="text-sm text-gray-600">
                            {stop.availability.map((slot, slotIndex) => (
                              <li key={slotIndex}>{slot.start} - {slot.end}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    <button
                      onClick={() => handleRemoveStop(index)}
                      className="text-red-600 hover:text-red-700"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      <div className="mt-8">
        <ItineraryList onEdit={handleEdit} />
      </div>
    </div>
  );
}