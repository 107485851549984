import { format, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export class TimezoneFormatter {
  static formatDateForTimezone(date: Date | string, timezone: string = 'UTC', formatStr: string = 'PPpp'): string {
    if (!date) {
      return format(new Date(), formatStr);
    }

    try {
      const dateObj = typeof date === 'string' ? parseISO(date) : date;
      if (isNaN(dateObj.getTime())) {
        throw new Error('Invalid date');
      }

      return formatInTimeZone(dateObj, timezone || 'UTC', formatStr);
    } catch (error) {
      console.error('Error formatting date:', error);
      return format(new Date(), formatStr);
    }
  }

  static getLocalTime(timezone: string = 'UTC'): string {
    try {
      if (!timezone) {
        return format(new Date(), 'HH:mm');
      }

      return formatInTimeZone(new Date(), timezone, 'HH:mm');
    } catch (error) {
      console.error('Error getting local time:', error);
      return format(new Date(), 'HH:mm');
    }
  }

  static isValidTimezone(timezone: string): boolean {
    if (!timezone) return false;
    
    try {
      formatInTimeZone(new Date(), timezone, 'HH:mm');
      return true;
    } catch {
      return false;
    }
  }
}