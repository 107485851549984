import { useState } from 'react';
import { generateAuthorizationUrl } from '../lib/ghlAuth';
import { Key } from 'lucide-react';
import { toast } from 'react-hot-toast';

export default function GHLConnect() {
  const [isConnecting, setIsConnecting] = useState(false);

  const handleConnect = () => {
    setIsConnecting(true);
    try {
      // Check if required environment variables are set
      if (!import.meta.env.VITE_GHL_CLIENT_ID || !import.meta.env.VITE_GHL_CLIENT_SECRET) {
        toast.error('GoHighLevel configuration is missing. Please check your environment variables.');
        setIsConnecting(false);
        return;
      }

      const authUrl = generateAuthorizationUrl();
      window.location.href = authUrl;
    } catch (error) {
      console.error('Failed to generate auth URL:', error);
      toast.error('Failed to connect to GoHighLevel');
      setIsConnecting(false);
    }
  };

  return (
    <div className="space-y-3">
      <div className="flex items-center space-x-3">
        <Key className="h-5 w-5 text-indigo-600" />
        <div>
          <h3 className="font-semibold">Connect GoHighLevel</h3>
          <p className="text-sm text-gray-600">Integrate with your GHL account</p>
        </div>
      </div>
      <button
        onClick={handleConnect}
        disabled={isConnecting}
        className="w-full px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:bg-indigo-400"
      >
        {isConnecting ? 'Connecting...' : 'Connect GHL Account'}
      </button>
    </div>
  );
}