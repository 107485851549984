import { format } from 'date-fns';
import { MapPin, Calendar, Clock, Trash2, Edit } from 'lucide-react';
import { Itinerary } from '../lib/types/itinerary';
import { useItineraries } from '../lib/hooks/useItineraries';

interface ItineraryListProps {
  onEdit: (itinerary: Itinerary) => void;
}

export default function ItineraryList({ onEdit }: ItineraryListProps) {
  const { itineraries, loading, error, deleteItinerary } = useItineraries();

  if (loading) {
    return (
      <div className="animate-pulse space-y-4">
        {[1, 2, 3].map((n) => (
          <div key={n} className="bg-gray-100 h-32 rounded-lg"></div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8 text-gray-600">
        <p>Failed to load itineraries. Please try again later.</p>
      </div>
    );
  }

  const itineraryList = Object.values(itineraries);

  if (itineraryList.length === 0) {
    return (
      <div className="text-center py-8 text-gray-600">
        <p>No saved itineraries yet.</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {itineraryList.map((itinerary) => (
        <div key={itinerary.id} className="bg-white rounded-lg shadow-md p-6">
          <div className="flex justify-between items-start">
            <div>
              <h3 className="text-xl font-semibold mb-2">{itinerary.name}</h3>
              <div className="space-y-2">
                <p className="text-sm text-gray-600 flex items-center">
                  <MapPin className="h-4 w-4 mr-2" />
                  {itinerary.stops.length} stops
                </p>
                <p className="text-sm text-gray-600 flex items-center">
                  <Calendar className="h-4 w-4 mr-2" />
                  Created {format(new Date(itinerary.createdAt), 'PPP')}
                </p>
                <p className="text-sm text-gray-600 flex items-center">
                  <Clock className="h-4 w-4 mr-2" />
                  Last updated {format(new Date(itinerary.updatedAt), 'PPP')}
                </p>
              </div>
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => onEdit(itinerary)}
                className="p-2 text-indigo-600 hover:bg-indigo-50 rounded-full"
                title="Edit itinerary"
              >
                <Edit className="h-5 w-5" />
              </button>
              <button
                onClick={() => deleteItinerary(itinerary.id)}
                className="p-2 text-red-600 hover:bg-red-50 rounded-full"
                title="Delete itinerary"
              >
                <Trash2 className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}