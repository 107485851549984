import { Itinerary, SavedItineraries } from '../../types/itinerary';
import { ItineraryStop } from '../../types/timezone';
import { auth, db } from '../../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';
import { nanoid } from 'nanoid';

export class ItineraryService {
  static async saveItinerary(name: string, stops: ItineraryStop[]): Promise<string | null> {
    const user = auth.currentUser;
    if (!user) {
      toast.error('Please sign in to save itineraries');
      return null;
    }

    try {
      const itineraryId = nanoid();
      const timestamp = new Date().toISOString();
      
      const newItinerary: Itinerary = {
        id: itineraryId,
        name,
        stops,
        createdAt: timestamp,
        updatedAt: timestamp,
        userId: user.uid
      };

      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const updatedItineraries = {
          ...userData.itineraries,
          [itineraryId]: newItinerary
        };

        await setDoc(userRef, { ...userData, itineraries: updatedItineraries }, { merge: true });
      } else {
        await setDoc(userRef, {
          itineraries: {
            [itineraryId]: newItinerary
          }
        }, { merge: true });
      }

      toast.success('Itinerary saved successfully');
      return itineraryId;
    } catch (err) {
      console.error('Error saving itinerary:', err);
      toast.error('Failed to save itinerary');
      return null;
    }
  }

  static async updateItinerary(id: string, updates: Partial<Itinerary>): Promise<void> {
    const user = auth.currentUser;
    if (!user) {
      toast.error('Please sign in to update itineraries');
      return;
    }

    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentItinerary = userData.itineraries?.[id];
        
        if (!currentItinerary) {
          throw new Error('Itinerary not found');
        }

        const updatedItinerary = {
          ...currentItinerary,
          ...updates,
          updatedAt: new Date().toISOString()
        };

        const updatedItineraries = {
          ...userData.itineraries,
          [id]: updatedItinerary
        };

        await setDoc(userRef, { ...userData, itineraries: updatedItineraries }, { merge: true });
        toast.success('Itinerary updated successfully');
      }
    } catch (err) {
      console.error('Error updating itinerary:', err);
      toast.error('Failed to update itinerary');
    }
  }

  static async deleteItinerary(id: string): Promise<void> {
    const user = auth.currentUser;
    if (!user) {
      toast.error('Please sign in to delete itineraries');
      return;
    }

    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const { [id]: removed, ...remainingItineraries } = userData.itineraries || {};
        
        await setDoc(userRef, { ...userData, itineraries: remainingItineraries }, { merge: true });
        toast.success('Itinerary deleted successfully');
      }
    } catch (err) {
      console.error('Error deleting itinerary:', err);
      toast.error('Failed to delete itinerary');
    }
  }

  static async getUserItineraries(): Promise<SavedItineraries> {
    const user = auth.currentUser;
    if (!user) return {};

    try {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        return userDoc.data().itineraries || {};
      }
      return {};
    } catch (err) {
      console.error('Error fetching itineraries:', err);
      toast.error('Failed to load itineraries');
      return {};
    }
  }
}