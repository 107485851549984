import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CalendlyService } from '../lib/services/calendly/calendlyService';
import { toast } from 'react-hot-toast';

export default function CalendlyCallback() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      const code = searchParams.get('code');
      const state = searchParams.get('state');

      if (!code || !state) {
        console.error('Missing required OAuth parameters:', { code, state });
        toast.error('Invalid OAuth callback parameters');
        navigate('/');
        return;
      }

      try {
        console.log('Processing Calendly callback...');
        await CalendlyService.handleAuthCallback(code, state);
        toast.success('Successfully connected to Calendly!');
        navigate('/');
      } catch (error) {
        console.error('OAuth callback error:', error);
        toast.error('Failed to connect to Calendly. Please try again.');
        navigate('/');
      }
    };

    handleCallback();
  }, [searchParams, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600 mx-auto mb-4"></div>
        <p className="text-gray-600">Connecting to Calendly...</p>
      </div>
    </div>
  );
}