import { useState } from 'react';
import { X } from 'lucide-react';
import { ItineraryStop } from '../lib/types/timezone';

interface SaveItineraryModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
  stops: ItineraryStop[];
}

export default function SaveItineraryModal({ isOpen, onClose, onSave, stops }: SaveItineraryModalProps) {
  const [name, setName] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!name.trim()) return;
    onSave(name.trim());
    setName('');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-xl p-8 max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X className="h-6 w-6" />
        </button>

        <h2 className="text-2xl font-bold mb-6">Save Itinerary</h2>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Itinerary Name
            </label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="e.g., Summer Europe Trip"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>

          <div className="text-sm text-gray-600">
            <p>This itinerary includes {stops.length} stops:</p>
            <ul className="mt-2 list-disc list-inside">
              {stops.map((stop, index) => (
                <li key={index}>{stop.location}</li>
              ))}
            </ul>
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors"
          >
            Save Itinerary
          </button>
        </form>
      </div>
    </div>
  );
}