import { useState } from 'react';
import { Clock, Plus, X } from 'lucide-react';
import { TimeSlot } from '../lib/types/timezone';

interface TimeSlotPickerProps {
  value: TimeSlot[];
  onChange: (slots: TimeSlot[]) => void;
  timezone: string;
}

export default function TimeSlotPicker({ value, onChange, timezone }: TimeSlotPickerProps) {
  const [newSlot, setNewSlot] = useState<TimeSlot>({ start: '09:00', end: '17:00' });

  const handleAddSlot = () => {
    if (!newSlot.start || !newSlot.end) return;
    
    // Convert to 24-hour time for comparison
    const start = new Date(`1970-01-01T${newSlot.start}`);
    const end = new Date(`1970-01-01T${newSlot.end}`);
    
    if (start >= end) {
      alert('End time must be after start time');
      return;
    }

    const overlapping = value.some(slot => {
      const existingStart = new Date(`1970-01-01T${slot.start}`);
      const existingEnd = new Date(`1970-01-01T${slot.end}`);
      return (start < existingEnd && end > existingStart);
    });

    if (overlapping) {
      alert('Time slots cannot overlap');
      return;
    }

    onChange([...value, newSlot]);
    setNewSlot({ start: '09:00', end: '17:00' });
  };

  const handleRemoveSlot = (index: number) => {
    onChange(value.filter((_, i) => i !== index));
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-2 mb-2">
        <Clock className="h-4 w-4 text-gray-500" />
        <span className="text-sm font-medium text-gray-700">Available Times ({timezone})</span>
      </div>

      <div className="flex items-end space-x-2">
        <div className="flex-1 grid grid-cols-2 gap-2">
          <div>
            <label className="block text-xs text-gray-600 mb-1">Start Time</label>
            <input
              type="time"
              value={newSlot.start}
              onChange={(e) => setNewSlot(prev => ({ ...prev, start: e.target.value }))}
              className="w-full px-2 py-1 border border-gray-300 rounded-md text-sm"
            />
          </div>
          <div>
            <label className="block text-xs text-gray-600 mb-1">End Time</label>
            <input
              type="time"
              value={newSlot.end}
              onChange={(e) => setNewSlot(prev => ({ ...prev, end: e.target.value }))}
              className="w-full px-2 py-1 border border-gray-300 rounded-md text-sm"
            />
          </div>
        </div>
        <button
          onClick={handleAddSlot}
          className="flex items-center justify-center h-8 w-8 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4" />
        </button>
      </div>

      {value.length > 0 && (
        <div className="space-y-2">
          {value.map((slot, index) => (
            <div
              key={index}
              className="flex items-center justify-between bg-gray-50 px-3 py-2 rounded-md"
            >
              <span className="text-sm text-gray-700">
                {slot.start} - {slot.end}
              </span>
              <button
                onClick={() => handleRemoveSlot(index)}
                className="text-gray-400 hover:text-red-600"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}