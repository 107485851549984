import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import SignUpModal from './components/SignUpModal';
import { auth } from './lib/firebase';
import { onAuthStateChanged } from 'firebase/auth';

export default function App() {
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('Basic');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      setIsVerified(user?.emailVerified ?? false);
    });

    return () => unsubscribe();
  }, []);

  const handleGetStarted = (plan: string) => {
    setSelectedPlan(plan);
    setIsSignUpModalOpen(true);
  };

  return (
    <div className="min-h-screen bg-gradient-radial from-indigo-50 via-white to-purple-50">
      <SignUpModal 
        isOpen={isSignUpModalOpen}
        onClose={() => setIsSignUpModalOpen(false)}
        selectedPlan={selectedPlan}
      />
      
      <Header 
        isAuthenticated={isAuthenticated}
        isVerified={isVerified}
        onGetStarted={handleGetStarted}
      />

      <Outlet context={{ isAuthenticated, isVerified }} />
    </div>
  );
}