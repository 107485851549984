import { Clock, Calendar, Users } from 'lucide-react';
import { useEffect, useState } from 'react';
import { auth, db, isPermissionError, isNetworkError } from '../lib/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { toast } from 'react-hot-toast';

const quotes = [
  "The future depends on what you do today. - Mahatma Gandhi",
  "Success is not final, failure is not fatal: it is the courage to continue that counts. - Winston Churchill",
  "The only way to do great work is to love what you do. - Steve Jobs",
  "Your time is limited, don't waste it living someone else's life. - Steve Jobs",
  "The best way to predict the future is to create it. - Peter Drucker"
];

export default function UserWelcome() {
  const [quote, setQuote] = useState('');
  const [firstName, setFirstName] = useState('');
  const [loading, setLoading] = useState(true);
  const [isOffline, setIsOffline] = useState(false);

  useEffect(() => {
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote);

    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        // First try to get the name from the user profile
        if (user.displayName) {
          setFirstName(user.displayName.split(' ')[0]);
        }

        // Then try to get additional data from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFirstName(userData.firstName || user.displayName?.split(' ')[0] || '');
        }
        setIsOffline(false);
      } catch (error: any) {
        console.error('Error fetching user data:', error);
        
        if (isNetworkError(error)) {
          setIsOffline(true);
          toast.error('You are currently offline. Some features may be limited.');
        } else if (!isPermissionError(error)) {
          toast.error('Unable to load user data');
        }
        
        // Fallback to display name
        if (user.displayName) {
          setFirstName(user.displayName.split(' ')[0]);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading) {
    return (
      <section className="py-12 px-6">
        <div className="container mx-auto">
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-xl animate-pulse">
            <div className="h-8 bg-gray-200 rounded w-1/3 mb-4"></div>
            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-12 px-6">
      <div className="container mx-auto">
        {isOffline && (
          <div className="mb-4 bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">
                  You are currently offline. Some features may be limited.
                </p>
              </div>
            </div>
          </div>
        )}
        
        <div className="bg-gradient-to-r from-indigo-600 to-purple-600 rounded-2xl p-8 shadow-xl text-white mb-8">
          <h2 className="text-3xl font-bold mb-3">
            Good {new Date().getHours() < 12 ? 'Morning' : new Date().getHours() < 17 ? 'Afternoon' : 'Evening'}, 
            {firstName ? ` ${firstName}` : ''}! 👋
          </h2>
          <p className="text-white/90 text-lg italic">"{quote}"</p>
        </div>

        <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-xl">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <Calendar className="h-8 w-8 text-indigo-600" />
                <div>
                  <h3 className="text-lg font-semibold">Today's Events</h3>
                  <p className="text-gray-600">3 upcoming meetings</p>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <Clock className="h-8 w-8 text-indigo-600" />
                <div>
                  <h3 className="text-lg font-semibold">Next Meeting</h3>
                  <p className="text-gray-600">2:00 PM - Team Sync</p>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <Users className="h-8 w-8 text-indigo-600" />
                <div>
                  <h3 className="text-lg font-semibold">Team Members</h3>
                  <p className="text-gray-600">5 online now</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}