import CryptoJS from 'crypto-js';
import axios from 'axios';
import { auth, db } from '../../firebase';
import { doc, updateDoc, getDoc, deleteField } from 'firebase/firestore';
import { CalendlyTokens } from '../../types/calendly';

const CALENDLY_TOKEN_URL = 'https://auth.calendly.com/oauth/token';

export class TokenService {
  static generateRandomState(): string {
    return CryptoJS.lib.WordArray.random(16).toString();
  }

  static async storeTokens(tokens: CalendlyTokens, tokenType: string): Promise<void> {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    const encryptedAccess = CryptoJS.AES.encrypt(
      tokens.access_token,
      import.meta.env.VITE_ENCRYPTION_KEY || ''
    ).toString();

    const encryptedRefresh = CryptoJS.AES.encrypt(
      tokens.refresh_token,
      import.meta.env.VITE_ENCRYPTION_KEY || ''
    ).toString();

    const expiresAt = Date.now() + tokens.expires_in * 1000;

    try {
      await updateDoc(doc(db, 'users', user.uid), {
        [`${tokenType}Tokens`]: {
          accessToken: encryptedAccess,
          refreshToken: encryptedRefresh,
          expiresAt,
          owner: tokens.owner,
          organization: tokens.organization
        }
      });
    } catch (error) {
      console.error('Error storing tokens:', error);
      throw new Error('Failed to store tokens');
    }
  }

  static async removeTokens(tokenType: string): Promise<void> {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user');

    try {
      await updateDoc(doc(db, 'users', user.uid), {
        [`${tokenType}Tokens`]: deleteField()
      });
    } catch (error) {
      console.error('Error removing tokens:', error);
      throw new Error('Failed to remove tokens');
    }
  }

  static async getStoredTokens(tokenType: string): Promise<{ 
    accessToken: string; 
    refreshToken: string; 
    expiresAt: number; 
  } | null> {
    const user = auth.currentUser;
    if (!user) return null;

    try {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (!userDoc.exists() || !userDoc.data()[`${tokenType}Tokens`]) return null;

      const { accessToken, refreshToken, expiresAt } = userDoc.data()[`${tokenType}Tokens`];

      return {
        accessToken: CryptoJS.AES.decrypt(
          accessToken, 
          import.meta.env.VITE_ENCRYPTION_KEY || ''
        ).toString(CryptoJS.enc.Utf8),
        refreshToken: CryptoJS.AES.decrypt(
          refreshToken, 
          import.meta.env.VITE_ENCRYPTION_KEY || ''
        ).toString(CryptoJS.enc.Utf8),
        expiresAt
      };
    } catch (error) {
      console.error('Error retrieving tokens:', error);
      return null;
    }
  }

  static async refreshAccessToken(refreshToken: string, tokenType: string): Promise<string> {
    try {
      const params = new URLSearchParams({
        grant_type: 'refresh_token',
        client_id: import.meta.env.VITE_CALENDLY_CLIENT_ID || '',
        client_secret: import.meta.env.VITE_CALENDLY_CLIENT_SECRET || '',
        refresh_token: refreshToken
      });

      const response = await axios.post<CalendlyTokens>(
        CALENDLY_TOKEN_URL,
        params.toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      await this.storeTokens(response.data, tokenType);
      return response.data.access_token;
    } catch (error) {
      console.error('Token refresh failed:', error);
      throw new Error('Failed to refresh access token');
    }
  }
}