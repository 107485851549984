import { useOutletContext } from 'react-router-dom';
import { Calendar, Sparkles, Clock, Users, Check } from 'lucide-react';
import UserDashboard from './UserDashboard';

interface OutletContext {
  isAuthenticated: boolean;
  isVerified: boolean;
}

export default function LandingPage() {
  const { isAuthenticated } = useOutletContext<OutletContext>();

  if (isAuthenticated) {
    return <UserDashboard />;
  }

  return (
    <div className="pt-20">
      {/* Hero Section */}
      <section className="pt-32 pb-20 px-6 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-indigo-50/50 to-transparent"></div>
        <div className="container mx-auto text-center relative z-10">
          <div className="inline-flex items-center bg-white/80 backdrop-blur-sm px-4 py-2 rounded-full mb-8 shadow-md">
            <Sparkles className="h-5 w-5 text-indigo-600 mr-2" />
            <span className="text-sm font-medium text-gray-600">AI-Powered Calendar Management</span>
          </div>
          <h1 className="text-6xl font-bold text-gray-900 mb-6 bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600">
            The Best Calendar App<br />
            <span className="text-indigo-600">for Digital Nomads</span>
          </h1>
          <p className="text-xl text-gray-600 mb-10 max-w-2xl mx-auto leading-relaxed">
            Stay organized across time zones, manage your schedule effortlessly, and never miss an important meeting again.
          </p>
          <button
            onClick={() => window.location.href = '#pricing'}
            className="btn-primary"
          >
            Get Started Free
          </button>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-20">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-2 gap-16 items-center">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-3xl transform rotate-3 opacity-10"></div>
              <img
                src="https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?auto=format&fit=crop&w=800&q=80"
                alt="Digital Nomad Working"
                className="rounded-2xl shadow-2xl relative z-10 transform hover:-rotate-2 transition-transform duration-300"
              />
            </div>
            <div className="space-y-8">
              <h2 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600 mb-6">
                Why Choose ZenCalAI?
              </h2>
              <div className="space-y-6">
                <FeatureCard
                  icon={<Clock className="h-6 w-6 text-indigo-600" />}
                  title="Smart Time Zone Management"
                  description="Automatically adjusts to your current location and shows meeting times in local and destination time zones."
                />
                <FeatureCard
                  icon={<Users className="h-6 w-6 text-indigo-600" />}
                  title="Team Collaboration"
                  description="Easily coordinate with team members across different time zones and schedule meetings that work for everyone."
                />
                <FeatureCard
                  icon={<Sparkles className="h-6 w-6 text-indigo-600" />}
                  title="AI-Powered Scheduling"
                  description="Let our AI find the perfect meeting times based on participants' availability and preferences."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-20 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-purple-50 to-indigo-50 opacity-50"></div>
        <div className="container mx-auto px-6 relative z-10">
          <h2 className="text-4xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-indigo-600 to-purple-600">
            Choose Your Plan
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            <PricingCard
              title="Basic"
              price="Free"
              features={[
                "Basic calendar features",
                "Time zone conversion",
                "Up to 3 calendars"
              ]}
              plan="Basic"
            />
            <PricingCard
              title="Pro"
              price="$9.99"
              features={[
                "All Basic features",
                "AI scheduling assistant",
                "Unlimited calendars",
                "Team collaboration"
              ]}
              plan="Pro"
              popular
            />
            <PricingCard
              title="Enterprise"
              price="$29.99"
              features={[
                "All Pro features",
                "Priority support",
                "Custom integrations",
                "Advanced analytics"
              ]}
              plan="Enterprise"
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

function FeatureCard({ icon, title, description }: { icon: React.ReactNode; title: string; description: string }) {
  return (
    <div className="card">
      <div className="flex items-start space-x-4">
        <div className="flex-shrink-0">
          {icon}
        </div>
        <div>
          <h3 className="font-semibold mb-2 text-lg">{title}</h3>
          <p className="text-gray-600">{description}</p>
        </div>
      </div>
    </div>
  );
}

function PricingCard({ title, price, features, plan, popular }: { 
  title: string; 
  price: string; 
  features: string[];
  plan: string;
  popular?: boolean;
}) {
  return (
    <div className={`card ${popular ? 'border-2 border-indigo-500 relative transform hover:scale-105' : 'hover:border-indigo-200'} transition-transform duration-300`}>
      {popular && (
        <div className="absolute top-0 right-0 bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-4 py-1 rounded-bl-lg rounded-tr-lg text-sm font-medium">
          Popular
        </div>
      )}
      <h3 className="text-xl font-semibold mb-4">{title}</h3>
      <p className="text-4xl font-bold mb-6">{price}<span className="text-base font-normal">/mo</span></p>
      <ul className="space-y-4 mb-8">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center space-x-3">
            <Check className="h-5 w-5 text-green-500" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <button
        onClick={() => window.location.href = `#signup?plan=${plan}`}
        className="btn-primary w-full"
      >
        Get Started
      </button>
    </div>
  );
}

function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-6">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center space-x-2 mb-6">
              <Calendar className="h-6 w-6 text-indigo-400" />
              <span className="text-xl font-bold bg-gradient-to-r from-indigo-400 to-purple-400 bg-clip-text text-transparent">
                ZenCalAI
              </span>
            </div>
            <p className="text-gray-400">Making time management zen for digital nomads worldwide.</p>
          </div>
          <FooterColumn
            title="Product"
            links={[
              { href: "#features", text: "Features" },
              { href: "#pricing", text: "Pricing" },
              { href: "#", text: "API" }
            ]}
          />
          <FooterColumn
            title="Company"
            links={[
              { href: "#about", text: "About" },
              { href: "#", text: "Blog" },
              { href: "#", text: "Careers" }
            ]}
          />
          <FooterColumn
            title="Legal"
            links={[
              { href: "#", text: "Privacy" },
              { href: "#", text: "Terms" },
              { href: "#", text: "Security" }
            ]}
          />
        </div>
        <div className="border-t border-gray-800 mt-12 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} ZenCalAI. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

function FooterColumn({ title, links }: { title: string; links: { href: string; text: string; }[] }) {
  return (
    <div>
      <h4 className="font-semibold mb-4 text-indigo-400">{title}</h4>
      <ul className="space-y-2 text-gray-400">
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.href} className="hover:text-white transition-colors">
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}