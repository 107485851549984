import { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { Itinerary, SavedItineraries } from '../types/itinerary';
import { ItineraryService } from '../services/itinerary/itineraryService';

export function useItineraries() {
  const [itineraries, setItineraries] = useState<SavedItineraries>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(
      doc(db, 'users', user.uid),
      (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setItineraries(data.itineraries || {});
        }
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching itineraries:', err);
        setError('Failed to load itineraries');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  return {
    itineraries,
    loading,
    error,
    saveItinerary: ItineraryService.saveItinerary,
    updateItinerary: ItineraryService.updateItinerary,
    deleteItinerary: ItineraryService.deleteItinerary
  };
}