import axios from 'axios';
import { auth } from '../firebase';
import { storeTokens, getStoredTokens } from './auth';
import { generateRandomState } from './oauth';
import { CalendlyTokens, CalendlyUser, CalendlyEvent } from '../types/calendly';

const CALENDLY_AUTH_URL = 'https://auth.calendly.com/oauth/authorize';
const CALENDLY_TOKEN_URL = 'https://auth.calendly.com/oauth/token';
const CALENDLY_API_URL = 'https://api.calendly.com/v2';
const REDIRECT_URI = `${window.location.origin}/calendly/callback`;

export function generateAuthUrl(): string {
  const state = generateRandomState();
  localStorage.setItem('calendly_oauth_state', state);

  const params = new URLSearchParams({
    client_id: import.meta.env.VITE_CALENDLY_CLIENT_ID || '',
    response_type: 'code',
    redirect_uri: REDIRECT_URI,
    state,
    scope: 'default'
  });

  return `${CALENDLY_AUTH_URL}?${params.toString()}`;
}

export async function handleAuthCallback(code: string, state: string): Promise<void> {
  const storedState = localStorage.getItem('calendly_oauth_state');
  if (state !== storedState) {
    throw new Error('Invalid state parameter');
  }

  try {
    const params = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: import.meta.env.VITE_CALENDLY_CLIENT_ID || '',
      client_secret: import.meta.env.VITE_CALENDLY_CLIENT_SECRET || '',
      code,
      redirect_uri: REDIRECT_URI
    });

    const response = await axios.post(CALENDLY_TOKEN_URL, params.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    await storeTokens(response.data, 'calendly');
    localStorage.removeItem('calendly_oauth_state');
  } catch (error) {
    console.error('Calendly auth error:', error);
    throw error;
  }
}

async function refreshAccessToken(refreshToken: string): Promise<string> {
  const params = new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: import.meta.env.VITE_CALENDLY_CLIENT_ID || '',
    client_secret: import.meta.env.VITE_CALENDLY_CLIENT_SECRET || '',
    refresh_token: refreshToken
  });

  const response = await axios.post<CalendlyTokens>(CALENDLY_TOKEN_URL, params.toString(), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

  await storeTokens(response.data, 'calendly');
  return response.data.access_token;
}

async function makeCalendlyRequest<T>(endpoint: string, method: string = 'GET', data?: unknown): Promise<T> {
  const tokens = await getStoredTokens('calendly');
  if (!tokens) throw new Error('No Calendly tokens available');

  let accessToken = tokens.accessToken;

  if (Date.now() >= tokens.expiresAt) {
    accessToken = await refreshAccessToken(tokens.refreshToken);
  }

  try {
    const response = await axios({
      method,
      url: `${CALENDLY_API_URL}${endpoint}`,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      data
    });

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      accessToken = await refreshAccessToken(tokens.refreshToken);
      
      const retryResponse = await axios({
        method,
        url: `${CALENDLY_API_URL}${endpoint}`,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        data
      });

      return retryResponse.data;
    }
    throw error;
  }
}

export async function getCurrentUser(): Promise<CalendlyUser> {
  const response = await makeCalendlyRequest<{ resource: CalendlyUser }>('/users/me');
  return response.resource;
}

export async function getEventTypes(): Promise<CalendlyEvent[]> {
  const user = await getCurrentUser();
  const response = await makeCalendlyRequest<{ collection: CalendlyEvent[] }>(`/event_types?user=${user.uri}`);
  return response.collection;
}