import UserWelcome from './UserWelcome';
import UserSettings from './UserSettings';

export default function UserDashboard() {
  return (
    <div className="space-y-8 pt-20">
      <UserWelcome />
      <UserSettings />
    </div>
  );
}