import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';

export function handleApiError(error: unknown): void {
  console.error('API Error:', error);

  if (error instanceof Error) {
    if ((error as AxiosError).isAxiosError) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        // Handle rate limiting
        if (axiosError.response.status === 429) {
          toast.error('Too many requests. Please try again later.');
          return;
        }
        // Handle API key issues
        if (axiosError.response.status === 401 || axiosError.response.status === 403) {
          toast.error('Unable to access timezone service. Using UTC.');
          return;
        }
        // Handle other server errors
        toast.error('Unable to fetch timezone information. Using UTC.');
      } else if (axiosError.request) {
        toast.error('Network error. Please check your connection.');
      } else {
        toast.error('Unable to process timezone request.');
      }
    } else {
      // Handle non-axios errors
      if (error.message.includes('API key')) {
        toast.error('Timezone service configuration error. Using UTC.');
      } else {
        toast.error('Unable to process timezone information.');
      }
    }
  } else {
    toast.error('An unexpected error occurred.');
  }
}