import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import OAuthCallback from './components/OAuthCallback';
import CalendlyCallback from './components/CalendlyCallback';
import LandingPage from './components/LandingPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <LandingPage />
      },
      {
        path: 'oauth/callback',
        element: <OAuthCallback />
      },
      {
        path: 'calendly/callback',
        element: <CalendlyCallback />
      }
    ]
  }
]);