import { findTimeZone, getZonedTime, listTimeZones } from 'timezone-support';
import { TimezoneInfo } from '../../types/timezone';
import { handleApiError } from '../../utils/error';

const commonLocations: Record<string, string> = {
  'new york': 'America/New_York',
  'los angeles': 'America/Los_Angeles',
  'chicago': 'America/Chicago',
  'london': 'Europe/London',
  'paris': 'Europe/Paris',
  'berlin': 'Europe/Berlin',
  'tokyo': 'Asia/Tokyo',
  'sydney': 'Australia/Sydney',
  'singapore': 'Asia/Singapore',
  'dubai': 'Asia/Dubai',
  'moscow': 'Europe/Moscow',
  'hong kong': 'Asia/Hong_Kong'
};

export class TimezoneService {
  static getTimezoneForLocation(location: string): TimezoneInfo {
    if (!location?.trim()) {
      return this.createDefaultTimezoneInfo();
    }

    try {
      const normalizedLocation = location.toLowerCase().trim();
      let timezoneName = commonLocations[normalizedLocation];

      if (!timezoneName) {
        const allTimezones = listTimeZones();
        const matchingTimezone = allTimezones.find(tz => 
          tz.toLowerCase().includes(normalizedLocation.replace(' ', '_'))
        );
        timezoneName = matchingTimezone || 'UTC';
      }

      const timezone = findTimeZone(timezoneName);
      const now = new Date();
      const zonedTime = getZonedTime(now, timezone);

      const [continent, ...locationParts] = timezoneName.split('/');
      const locationName = locationParts.join('/').replace(/_/g, ' ');

      return {
        timezone: timezoneName,
        datetime: now.toISOString(),
        timestamp: now.getTime(),
        gmtOffset: zonedTime.zone.offset * 60,
        countryCode: continent,
        countryName: locationName || continent
      };
    } catch (error) {
      handleApiError(error);
      return this.createDefaultTimezoneInfo();
    }
  }

  private static createDefaultTimezoneInfo(): TimezoneInfo {
    return {
      timezone: 'UTC',
      datetime: new Date().toISOString(),
      timestamp: Date.now(),
      gmtOffset: 0,
      countryCode: 'UTC',
      countryName: 'Coordinated Universal Time'
    };
  }
}