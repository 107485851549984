import { useState } from 'react';
import { Calendar } from 'lucide-react';
import { auth } from '../lib/firebase';
import { signOut } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import SignInModal from './SignInModal';

interface HeaderProps {
  isAuthenticated: boolean;
  isVerified: boolean;
  onGetStarted: (plan: string) => void;
}

export default function Header({ isAuthenticated, isVerified, onGetStarted }: HeaderProps) {
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      toast.success('Signed out successfully');
    } catch (error: any) {
      toast.error('Error signing out');
    }
  };

  return (
    <>
      <SignInModal 
        isOpen={isSignInModalOpen}
        onClose={() => setIsSignInModalOpen(false)}
      />
      
      <header className="fixed w-full bg-white/80 backdrop-blur-md z-50 border-b border-gray-100">
        <div className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Calendar className="h-8 w-8 text-indigo-600" />
              <span className="text-2xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                ZenCalAI
              </span>
            </div>
            <nav className="hidden md:flex items-center space-x-8">
              <a href="#features" className="text-gray-600 hover:text-indigo-600 transition-colors">Features</a>
              <a href="#about" className="text-gray-600 hover:text-indigo-600 transition-colors">About</a>
              <a href="#pricing" className="text-gray-600 hover:text-indigo-600 transition-colors">Pricing</a>
              {isAuthenticated ? (
                <button
                  onClick={handleSignOut}
                  className="text-gray-600 hover:text-indigo-600 transition-colors"
                >
                  Sign Out
                </button>
              ) : (
                <button 
                  onClick={() => setIsSignInModalOpen(true)}
                  className="text-gray-600 hover:text-indigo-600 transition-colors"
                >
                  Sign In
                </button>
              )}
              {!isAuthenticated && (
                <button 
                  onClick={() => onGetStarted('Basic')}
                  className="bg-indigo-600 text-white px-6 py-2 rounded-full hover:bg-indigo-700 transition-colors"
                >
                  Get Started
                </button>
              )}
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}