import axios from 'axios';
import CryptoJS from 'crypto-js';
import { auth, db } from './firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

// Get the current application URL for the redirect URI
const APP_URL = window.location.origin;
// Updated to use the correct GHL authorization URL
const GHL_AUTH_URL = 'https://marketplace.gohighlevel.com/oauth/chooselocation';
const GHL_TOKEN_URL = 'https://services.gohighlevel.com/oauth/token';
const GHL_API_BASE = 'https://services.gohighlevel.com/v1/api/';
const REDIRECT_URI = `${APP_URL}/oauth/callback`;

// Generate a random state for CSRF protection
export function generateRandomState(): string {
  return CryptoJS.lib.WordArray.random(16).toString();
}

// Generate the authorization URL with correct scope and marketplace URL
export function generateAuthorizationUrl(): string {
  const state = generateRandomState();
  
  // Store state in localStorage for validation
  localStorage.setItem('ghl_oauth_state', state);
  
  const params = new URLSearchParams({
    response_type: 'code',
    client_id: import.meta.env.VITE_GHL_CLIENT_ID || '',
    redirect_uri: REDIRECT_URI,
    scope: 'calendars.readonly contacts.readonly locations.readonly businesses.readonly',
    state
  });
  
  return `${GHL_AUTH_URL}?${params.toString()}`;
}

// Handle the OAuth callback
export async function handleOAuthCallback(code: string, state: string): Promise<void> {
  const storedState = localStorage.getItem('ghl_oauth_state');
  
  if (state !== storedState) {
    throw new Error('Invalid state parameter');
  }
  
  try {
    const tokenData = new URLSearchParams({
      grant_type: 'authorization_code',
      code,
      client_id: import.meta.env.VITE_GHL_CLIENT_ID || '',
      client_secret: import.meta.env.VITE_GHL_CLIENT_SECRET || '',
      redirect_uri: REDIRECT_URI
    });

    const response = await axios.post(GHL_TOKEN_URL, tokenData.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      }
    });

    const { access_token, refresh_token, expires_in } = response.data;
    await storeTokens(access_token, refresh_token, expires_in);
    
    // Clear state from localStorage
    localStorage.removeItem('ghl_oauth_state');
    
  } catch (error) {
    console.error('Token exchange failed:', error);
    throw error;
  }
}

// Store tokens securely in Firestore
async function storeTokens(accessToken: string, refreshToken: string, expiresIn: number): Promise<void> {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const encryptedAccess = CryptoJS.AES.encrypt(accessToken, import.meta.env.VITE_ENCRYPTION_KEY || '').toString();
  const encryptedRefresh = CryptoJS.AES.encrypt(refreshToken, import.meta.env.VITE_ENCRYPTION_KEY || '').toString();
  const expiresAt = Date.now() + (expiresIn * 1000);

  await updateDoc(doc(db, 'users', user.uid), {
    ghlTokens: {
      accessToken: encryptedAccess,
      refreshToken: encryptedRefresh,
      expiresAt
    }
  });
}

// Retrieve and decrypt tokens
async function retrieveTokens(): Promise<{ accessToken: string; refreshToken: string; expiresAt: number } | null> {
  const user = auth.currentUser;
  if (!user) return null;

  const userDoc = await getDoc(doc(db, 'users', user.uid));
  if (!userDoc.exists() || !userDoc.data().ghlTokens) return null;

  const { accessToken, refreshToken, expiresAt } = userDoc.data().ghlTokens;

  return {
    accessToken: CryptoJS.AES.decrypt(accessToken, import.meta.env.VITE_ENCRYPTION_KEY || '').toString(CryptoJS.enc.Utf8),
    refreshToken: CryptoJS.AES.decrypt(refreshToken, import.meta.env.VITE_ENCRYPTION_KEY || '').toString(CryptoJS.enc.Utf8),
    expiresAt
  };
}

// Refresh access token
async function refreshAccessToken(refreshToken: string): Promise<string> {
  try {
    const tokenData = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: import.meta.env.VITE_GHL_CLIENT_ID || '',
      client_secret: import.meta.env.VITE_GHL_CLIENT_SECRET || ''
    });

    const response = await axios.post(GHL_TOKEN_URL, tokenData.toString(), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      }
    });

    const { access_token, refresh_token, expires_in } = response.data;
    await storeTokens(access_token, refresh_token, expires_in);

    return access_token;
  } catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
  }
}

// Make authenticated API requests
export async function makeGHLApiRequest(endpoint: string, method: string = 'GET', data?: any) {
  const tokens = await retrieveTokens();
  if (!tokens) throw new Error('No tokens available');

  let accessToken = tokens.accessToken;

  // Check if token is expired
  if (Date.now() >= tokens.expiresAt) {
    accessToken = await refreshAccessToken(tokens.refreshToken);
  }

  try {
    const response = await axios({
      method,
      url: `${GHL_API_BASE}${endpoint}`,
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Version': '2021-07-28',
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data
    });

    return response.data;
  } catch (error: any) {
    if (error.response?.status === 401) {
      // Token might be invalid, try refreshing
      accessToken = await refreshAccessToken(tokens.refreshToken);
      
      // Retry request with new token
      const retryResponse = await axios({
        method,
        url: `${GHL_API_BASE}${endpoint}`,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Version': '2021-07-28',
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data
      });

      return retryResponse.data;
    }
    throw error;
  }
}