import { useState, useEffect, useCallback } from 'react';
import { getCurrentUser, getEventTypes } from '../api/calendly';
import { CalendlyUser, CalendlyEvent } from '../types/calendly';
import { toast } from 'react-hot-toast';

export function useCalendly() {
  const [user, setUser] = useState<CalendlyUser | null>(null);
  const [events, setEvents] = useState<CalendlyEvent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const userData = await getCurrentUser();
      if (userData) {
        setUser({
          uri: userData.uri,
          name: userData.name,
          slug: userData.slug,
          email: userData.email,
          scheduling_url: userData.scheduling_url,
          timezone: userData.timezone
        });

        const eventTypes = await getEventTypes();
        if (Array.isArray(eventTypes)) {
          setEvents(eventTypes.map(event => ({
            uri: event.uri,
            name: event.name,
            duration: event.duration,
            scheduling_url: event.scheduling_url
          })));
        }
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to load Calendly data';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {
      if (!mounted) return;
      await fetchData();
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, [fetchData]);

  const refreshData = useCallback(async () => {
    setLoading(true);
    await fetchData();
  }, [fetchData]);

  return {
    user,
    events,
    loading,
    error,
    refreshData
  };
}