import { Clock } from 'lucide-react';
import { useState, useEffect } from 'react';
import { TimezoneFormatter } from '../lib/services/timezone/timezoneFormatter';

interface TimezoneDisplayProps {
  timezone: string;
  className?: string;
}

export default function TimezoneDisplay({ timezone, className = '' }: TimezoneDisplayProps) {
  const [localTime, setLocalTime] = useState(() => TimezoneFormatter.getLocalTime(timezone));
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    const valid = TimezoneFormatter.isValidTimezone(timezone);
    setIsValid(valid);
    
    if (!valid) {
      console.warn(`Invalid timezone: ${timezone}`);
      return;
    }

    setLocalTime(TimezoneFormatter.getLocalTime(timezone));
    const timer = setInterval(() => {
      setLocalTime(TimezoneFormatter.getLocalTime(timezone));
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, [timezone]);

  if (!isValid) {
    return (
      <div className={`flex items-center space-x-2 ${className}`}>
        <Clock className="h-4 w-4 text-gray-500" />
        <span className="text-sm text-gray-600">
          UTC (default)
        </span>
      </div>
    );
  }

  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <Clock className="h-4 w-4 text-gray-500" />
      <span className="text-sm text-gray-600">
        {timezone} ({localTime})
      </span>
    </div>
  );
}