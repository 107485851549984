import React, { useState } from 'react';
import { auth, db, sendVerificationEmail } from '../lib/firebase';
import { 
  createUserWithEmailAndPassword, 
  GoogleAuthProvider, 
  signInWithPopup,
  AuthErrorCodes 
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { X } from 'lucide-react';
import toast from 'react-hot-toast';

interface SignUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedPlan?: string;
}

export default function SignUpModal({ isOpen, onClose, selectedPlan = 'Basic' }: SignUpModalProps) {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    plan: selectedPlan
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const getErrorMessage = (error: any) => {
    switch (error.code) {
      case AuthErrorCodes.EMAIL_EXISTS:
        return 'This email is already registered. Please sign in instead.';
      case AuthErrorCodes.WEAK_PASSWORD:
        return 'Password should be at least 6 characters long.';
      case AuthErrorCodes.INVALID_EMAIL:
        return 'Please enter a valid email address.';
      default:
        return error.message || 'Failed to create account. Please try again.';
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Create user account
      const { user } = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      // Create user document in Firestore
      try {
        await setDoc(doc(db, 'users', user.uid), {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          plan: formData.plan,
          createdAt: new Date().toISOString(),
          isActive: false
        });
      } catch (firestoreError: any) {
        console.error('Firestore error:', firestoreError);
        // Continue with sign up even if Firestore fails
      }

      // Send verification email
      const emailSent = await sendVerificationEmail();
      
      if (emailSent) {
        toast.success('Please check your email to verify your account before signing in.', {
          duration: 5000,
          icon: '✉️'
        });
      } else {
        toast.error('Account created but verification email could not be sent. Please try resending later.');
      }
      
      // Reset form and close modal
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        plan: selectedPlan
      });
      onClose();
      
    } catch (error: any) {
      console.error('Sign up error:', error);
      toast.error(getErrorMessage(error));
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const { user } = await signInWithPopup(auth, provider);

      try {
        const names = user.displayName?.split(' ') || ['', ''];
        await setDoc(doc(db, 'users', user.uid), {
          firstName: names[0],
          lastName: names[1],
          email: user.email,
          plan: formData.plan,
          createdAt: new Date().toISOString(),
          isActive: true
        });
      } catch (firestoreError: any) {
        console.error('Firestore error:', firestoreError);
        // Continue with sign in even if Firestore fails
      }

      toast.success('Signed in successfully!');
      onClose();
    } catch (error: any) {
      console.error('Google sign in error:', error);
      toast.error(error.message || 'Failed to sign in with Google. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-xl p-8 max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X className="h-6 w-6" />
        </button>

        <h2 className="text-2xl font-bold mb-6">Create your account</h2>
        
        <button
          onClick={handleGoogleSignIn}
          className="w-full bg-white border border-gray-300 text-gray-700 px-4 py-2 rounded-full mb-6 flex items-center justify-center space-x-2 hover:bg-gray-50 transition-colors"
        >
          <img src="https://www.google.com/favicon.ico" alt="Google" className="w-5 h-5" />
          <span>Continue with Google</span>
        </button>

        <div className="relative mb-6">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300"></div>
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">Or continue with email</span>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                required
                value={formData.firstName}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                required
                value={formData.lastName}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              name="password"
              required
              minLength={6}
              value={formData.password}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Selected Plan
            </label>
            <select
              name="plan"
              value={formData.plan}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 bg-white"
            >
              <option value="Basic">Basic</option>
              <option value="Pro">Pro</option>
              <option value="Enterprise">Enterprise</option>
            </select>
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white px-4 py-2 rounded-full hover:bg-indigo-700 transition-colors"
          >
            Create Account
          </button>
        </form>
      </div>
    </div>
  );
}