import { Clock, ExternalLink } from 'lucide-react';
import { CalendlyEvent } from '../lib/types/calendly';

interface CalendlyEventCardProps {
  event: CalendlyEvent;
}

export default function CalendlyEventCard({ event }: CalendlyEventCardProps) {
  return (
    <div className="bg-white rounded-lg border border-gray-200 p-4 hover:shadow-md transition-shadow">
      <div className="flex justify-between items-start">
        <div>
          <h4 className="font-medium">{event.name}</h4>
          <div className="flex items-center space-x-2 text-sm text-gray-600 mt-1">
            <Clock className="h-4 w-4" />
            <span>{event.duration} minutes</span>
          </div>
        </div>
        <a
          href={event.scheduling_url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-indigo-600 hover:text-indigo-700"
        >
          <ExternalLink className="h-4 w-4" />
        </a>
      </div>
    </div>
  );
}