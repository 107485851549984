import { Calendar, ExternalLink, LogOut } from 'lucide-react';
import { useCalendly } from '../lib/hooks/useCalendly';
import { CalendlyService } from '../lib/services/calendly/calendlyService';
import CalendlyEventCard from './CalendlyEventCard';
import { toast } from 'react-hot-toast';

export default function CalendlyEvents() {
  const { user, events, loading, error, refreshData } = useCalendly();

  const handleDisconnect = async () => {
    try {
      await CalendlyService.disconnect();
      window.location.reload(); // Refresh the page to update UI state
    } catch (err) {
      toast.error('Failed to disconnect from Calendly');
    }
  };

  if (loading) {
    return (
      <div className="space-y-4 animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/3"></div>
        <div className="space-y-3">
          {[1, 2, 3].map((n) => (
            <div key={n} className="h-24 bg-gray-100 rounded-lg"></div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  if (!user) return null;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <Calendar className="h-5 w-5 text-indigo-600" />
          <div>
            <h3 className="font-semibold">Calendly Events</h3>
            <p className="text-sm text-gray-600">{user.name} • {user.timezone}</p>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <a
            href={user.scheduling_url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-600 hover:text-indigo-700 flex items-center space-x-1"
          >
            <span className="text-sm">View Profile</span>
            <ExternalLink className="h-4 w-4" />
          </a>
          <button
            onClick={handleDisconnect}
            className="flex items-center space-x-2 px-3 py-1 text-red-600 hover:text-red-700 hover:bg-red-50 rounded-lg transition-colors"
          >
            <LogOut className="h-4 w-4" />
            <span className="text-sm">Disconnect</span>
          </button>
        </div>
      </div>

      <div className="space-y-4">
        {events.map((event) => (
          <CalendlyEventCard key={event.uri} event={event} />
        ))}
      </div>
    </div>
  );
}