import axios from 'axios';
import { auth } from '../../firebase';
import { CalendlyTokens, CalendlyUser, CalendlyEvent } from '../../types/calendly';
import { TokenService } from './tokenService';
import { toast } from 'react-hot-toast';

const CALENDLY_AUTH_URL = 'https://auth.calendly.com/oauth/authorize';
const CALENDLY_TOKEN_URL = 'https://auth.calendly.com/oauth/token';
const CALENDLY_API_URL = 'https://api.calendly.com/v2';

export class CalendlyService {
  private static REDIRECT_URI = `${window.location.origin}/calendly/callback`;

  static generateAuthUrl(): string {
    const state = TokenService.generateRandomState();
    localStorage.setItem('calendly_oauth_state', state);

    const params = new URLSearchParams({
      client_id: import.meta.env.VITE_CALENDLY_CLIENT_ID || '',
      response_type: 'code',
      redirect_uri: this.REDIRECT_URI,
      state,
      scope: 'default'
    });

    const authUrl = `${CALENDLY_AUTH_URL}?${params.toString()}`;
    console.log('Generated Calendly auth URL:', authUrl);
    console.log('Redirect URI:', this.REDIRECT_URI);
    return authUrl;
  }

  static async handleAuthCallback(code: string, state: string): Promise<void> {
    console.log('Handling Calendly callback with code:', code);
    const storedState = localStorage.getItem('calendly_oauth_state');
    if (state !== storedState) {
      console.error('State mismatch:', { received: state, stored: storedState });
      throw new Error('Invalid state parameter');
    }

    try {
      const params = new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: import.meta.env.VITE_CALENDLY_CLIENT_ID || '',
        client_secret: import.meta.env.VITE_CALENDLY_CLIENT_SECRET || '',
        code,
        redirect_uri: this.REDIRECT_URI
      });

      console.log('Making token exchange request to:', CALENDLY_TOKEN_URL);
      console.log('With redirect URI:', this.REDIRECT_URI);
      
      const response = await axios.post(CALENDLY_TOKEN_URL, params.toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json'
        }
      });

      console.log('Successfully received Calendly tokens');
      await TokenService.storeTokens(response.data, 'calendly');
      localStorage.removeItem('calendly_oauth_state');
    } catch (error) {
      console.error('Calendly auth error:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response?.data);
        console.error('Status:', error.response?.status);
        console.error('Headers:', error.response?.headers);
        
        if (error.response?.status === 404) {
          console.error('404 Not Found - Please verify the Calendly OAuth application settings');
          toast.error('Invalid Calendly configuration. Please check the application settings.');
        } else if (error.response?.status === 401) {
          console.error('401 Unauthorized - Invalid client credentials');
          toast.error('Invalid Calendly credentials');
        }
      }
      throw error;
    }
  }

  static async getCurrentUser(): Promise<CalendlyUser> {
    console.log('Fetching current Calendly user...');
    const response = await this.makeCalendlyRequest<{ resource: CalendlyUser }>('/users/me');
    console.log('Received user data:', response);
    return response.resource;
  }

  static async getEventTypes(): Promise<CalendlyEvent[]> {
    const user = await this.getCurrentUser();
    console.log('Fetching event types for user:', user.uri);
    const response = await this.makeCalendlyRequest<{ collection: CalendlyEvent[] }>(
      `/event_types?user=${encodeURIComponent(user.uri)}`
    );
    console.log('Received event types:', response);
    return response.collection;
  }

  static async disconnect(): Promise<void> {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('No authenticated user');
    }

    try {
      await TokenService.removeTokens('calendly');
      toast.success('Successfully disconnected from Calendly');
    } catch (error) {
      console.error('Error disconnecting from Calendly:', error);
      throw error;
    }
  }

  private static async makeCalendlyRequest<T>(
    endpoint: string,
    method: string = 'GET',
    data?: unknown
  ): Promise<T> {
    const tokens = await TokenService.getStoredTokens('calendly');
    if (!tokens) throw new Error('No Calendly tokens available');

    let accessToken = tokens.accessToken;

    if (Date.now() >= tokens.expiresAt) {
      console.log('Access token expired, refreshing...');
      accessToken = await TokenService.refreshAccessToken(tokens.refreshToken, 'calendly');
    }

    try {
      const url = `${CALENDLY_API_URL}${endpoint}`;
      console.log(`Making Calendly API request to: ${url}`);
      
      const response = await axios({
        method,
        url,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        data
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        console.log('Token expired during request, refreshing...');
        accessToken = await TokenService.refreshAccessToken(tokens.refreshToken, 'calendly');
        
        const retryResponse = await axios({
          method,
          url: `${CALENDLY_API_URL}${endpoint}`,
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          data
        });

        return retryResponse.data;
      }
      
      console.error('Calendly API request failed:', error);
      if (axios.isAxiosError(error)) {
        console.error('Response:', error.response?.data);
        console.error('Status:', error.response?.status);
        
        if (error.response?.status === 404) {
          toast.error('Calendly API endpoint not found');
        } else if (error.response?.status === 401) {
          toast.error('Calendly authentication failed');
        } else {
          toast.error('Failed to communicate with Calendly');
        }
      }
      throw error;
    }
  }
}