import { auth, db } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import CryptoJS from 'crypto-js';

export async function storeTokens(tokens: any, tokenType: string): Promise<void> {
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user');

  const encryptedAccess = CryptoJS.AES.encrypt(
    tokens.access_token,
    import.meta.env.VITE_ENCRYPTION_KEY || ''
  ).toString();

  const encryptedRefresh = CryptoJS.AES.encrypt(
    tokens.refresh_token,
    import.meta.env.VITE_ENCRYPTION_KEY || ''
  ).toString();

  const expiresAt = Date.now() + tokens.expires_in * 1000;

  await updateDoc(doc(db, 'users', user.uid), {
    [`${tokenType}Tokens`]: {
      accessToken: encryptedAccess,
      refreshToken: encryptedRefresh,
      expiresAt,
      owner: tokens.owner,
      organization: tokens.organization
    }
  });
}

export async function getStoredTokens(tokenType: string): Promise<{ accessToken: string; refreshToken: string; expiresAt: number } | null> {
  const user = auth.currentUser;
  if (!user) return null;

  const userDoc = await getDoc(doc(db, 'users', user.uid));
  if (!userDoc.exists() || !userDoc.data()[`${tokenType}Tokens`]) return null;

  const { accessToken, refreshToken, expiresAt } = userDoc.data()[`${tokenType}Tokens`];

  return {
    accessToken: CryptoJS.AES.decrypt(accessToken, import.meta.env.VITE_ENCRYPTION_KEY || '').toString(CryptoJS.enc.Utf8),
    refreshToken: CryptoJS.AES.decrypt(refreshToken, import.meta.env.VITE_ENCRYPTION_KEY || '').toString(CryptoJS.enc.Utf8),
    expiresAt
  };
}